<template>
    <div class="chart-header-container d-flex">
        <div class="title">
            <h4 class="text-primary mb-0">{{ title  }}</h4>
        </div>
        <div class="widgets">
            <profile-chart-buttons v-if="display" ref="chartButtons" 
              :input-display-number-of-responses="inputDisplayNumberOfResponses"
              :display-show-respondents="displayShowRespondents"
              @set-display-number-of-responses="setDisplayNumberOfResponses"
            />
        </div>
    </div>
</template>

<script>
import ProfileChartButtons from "./ProfileChartButtons.vue";

import $ from "jquery";

export default {
  name: "ProfileChartHeader",
  mixins: [],
  components: {ProfileChartButtons},
  data() {
    return {
    };
  },
  props: {
    title: {
        type: String,
        default: ""
    },     
    display: {
        type: Boolean,
        default: true
    },
    inputDisplayNumberOfResponses: {
        type: Boolean,
        default: true
    },     
    displayShowRespondents: {
        type: Boolean,
        default: true
    },    
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    setDisplayNumberOfResponses(inputDisplayNumberOfResponses){
        this.$emit("set-display-number-of-responses", inputDisplayNumberOfResponses);
    },    
  },
  watch: {},
};
</script>
